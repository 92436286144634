import { callBackend } from "../callBackend";
const apiPath = "api/user/auth/telegram_login";

export async function authMiniApp(initData) {
  let backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;
  if (!backendDomain) {
    return { success: false, message: "no backendDomain" };
  }

  const paramsInitData = new URLSearchParams(initData);
  let jsonInitData = Object.fromEntries(paramsInitData);
  try {
    jsonInitData.user = JSON.parse(jsonInitData.user);
  } catch (error) {
    console.log("empty initData");
    return { success: false, message: "empty initData" };
  }

  let responce = await callBackend({
    link: `${backendDomain}/${apiPath}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: {
      data: jsonInitData,
    },
  });

  return responce;
}
