import styles from "../styles/ProfilePage.module.css";
import { useNavigate, useLocation } from "react-router-dom";

import {
 textFiat,
 textCript,
 textTopUp,
 textRefLink,
 textRefLinkDescription,
 textBalance,
 textCashOut,
 textCopyLink,
} from "../consts/ConstSevicePage";
import { getBalance } from "../functions/api/getBalance";
import { useEffect, useState } from "react";
import { getRef } from "../functions/api/getRef";
import NavBar from "../components/NavBar";

const ProfilePage = ({}) => {
  const { state } = useLocation();
  const { prevPath, prevState } = state;
  const [fiatAmount, setFiatAmount] = useState(0);
  const [criptAmount, setCriptAmount] = useState(0);
  const [refLink, setRefLink] = useState("");
  const [isCopyMessageVisible, setCopyMessageVisibility] = useState(false);
  let messageVisibilityTimeout;
  useEffect(() => {
    (async () => {
      let balance = (await getBalance()).balance;
      console.log("balance", balance);
      balance?.RUB && setFiatAmount(balance.RUB);
      balance?.USDT && setCriptAmount(balance.USDT);

      let refLink = (await getRef())?.link?.link;
      refLink && setRefLink(refLink);
    })();
  }, []);

  console.log({ fiatAmount: fiatAmount, criptAmount: criptAmount });

  let user = window.Telegram.WebApp?.initDataUnsafe?.user;
  let username = "";
  if (user) {
    username = user.firstname
      ? `${user.first_name} ${user.last_name}`
      : user.username;
  }

  function handleClick() {
    window.Telegram.WebApp.openTelegramLink("https://t.me/easehow");
    window.Telegram.WebApp.close();
  }

  const navigate = useNavigate();
  let style = {
    display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    width: "100vw",
    height: "100vh",
  };

  return (
    <div style={style}>
      <div class="container">
        <div className="header">
          <div className={styles.header_inner_text}>
            <h1>
              {/* <img src="/ic/profile.svg" /> */}
              {username}
            </h1>
            <p className={styles.price}>
                {textBalance} 
                <text className="price_value">{criptAmount}</text>
                <nobr> {textCript}</nobr>
            </p>
            <div className={styles.header_inner_buttons}>
              <button onClick={handleClick}>{textTopUp}</button>
              <button
                onClick={handleClick}
                style={
                  fiatAmount == 0 || criptAmount == 0
                    ? { opacity: 0.2, pointerEvents: "none" }
                    : { opacity: 1 }
                }
              >
                {textCashOut}
              </button>
            </div>
          </div>
          <div className={styles.blockRefLink}>
            <div className={styles.textRefLink}>
              <h2>{textRefLink}</h2>
              <p>{textRefLinkDescription}</p>
            </div>
            <p
              style={Object.assign(
                {
                  margin: "0 calc(var(--bt_pad_w) * 1)",
                  padding: 0,
                  transition: ".2s all ease-out",
                },
                isCopyMessageVisible
                  ? { visibility: "visible", opacity: 1 }
                  : { visibility: "hidden", opacity: 0 }
              )}
            >
              {textCopyLink}
            </p>
            <button
              onClick={(event) => {
                navigator.clipboard.writeText(event.target.innerText);
                setCopyMessageVisibility(true);
                clearTimeout(messageVisibilityTimeout);
                messageVisibilityTimeout = setTimeout(() => {
                  setCopyMessageVisibility(false);
                }, 2000);
              }}
            >
              https://t.me/easehowbot/easehow?startapp={refLink}
            </button>
          </div>
        </div>

        <NavBar prevPagePath={prevPath} prevPageState={prevState} />
      </div>
    </div>
  );
};

export default ProfilePage;
