// ButtonServiceData.js – данные для кнопок услуг на первом экране
const ButtonServiceData = {
'Тренер DOTA': { url: 'trainer_dota2', costPerHourRUB: 1500, costPerHourUSDT: 13 }, 
'Английский': { url: 'english', costPerHourRUB: 2750, costPerHourUSDT: 35 },
'Русский': { url: 'russian', costPerHourRUB: 2000, costPerHourUSDT: 30 },
'Математика': { url: 'mathematics', costPerHourRUB: 2700, costPerHourUSDT: 30 },
'Китайский': { url: 'chinese', costPerHourRUB: 3000, costPerHourUSDT: 30 },
'Литература': { url: 'literature', costPerHourRUB: 2500, costPerHourUSDT: 30 },
'Физика': { url: 'physics', costPerHourRUB: 2700, costPerHourUSDT: 30 },
'Биология': { url: 'biology', costPerHourRUB: 1000, costPerHourUSDT: 30 },
'Немецкий': { url: 'german', costPerHourRUB: 3000, costPerHourUSDT: 35 },
'Французский': { url: 'french', costPerHourRUB: 5000, costPerHourUSDT: 50 },
'Информатика': { url: 'computer_science', costPerHourRUB: 2700, costPerHourUSDT: 35 },
'3d': { url: '3d', costPerHourRUB: 2000, costPerHourUSDT: 23 },
'Python': { url: 'python', costPerHourRUB: 3500, costPerHourUSDT: 35 },
'Рисование': { url: 'drawing', costPerHourRUB: 2000, costPerHourUSDT: 23 },
'Анимация': { url: 'animation', costPerHourRUB: 2000, costPerHourUSDT: 23 },
'Итальянский': { url: 'italian', costPerHourRUB: 2500, costPerHourUSDT: 50 },
'Философия': { url: 'philosophy', costPerHourRUB: 2700, costPerHourUSDT: 30 },
'Юрист': { url: 'lawyer', costPerHourRUB: 2500, costPerHourUSDT: 39 },
'Испанский': { url: 'spanish', costPerHourRUB: 3000, costPerHourUSDT: 30 },
'Java': { url: 'java', costPerHourRUB: 3500, costPerHourUSDT: 35 },
'Бухгалтер': { url: 'accountant', costPerHourRUB: 2700, costPerHourUSDT: 30 },
'PHP': { url: 'php', costPerHourRUB: 3500, costPerHourUSDT: 35 },
'SQL': { url: 'sql', costPerHourRUB: 2000, costPerHourUSDT: 21 },
'Тендеры': { url: 'tenders', costPerHourRUB: 2700, costPerHourUSDT: 30 },
'Франшизы': { url: 'franchises', costPerHourRUB: 2700, costPerHourUSDT: 30 },
'JavaScript': { url: 'javascript', costPerHourRUB: 3500, costPerHourUSDT: 35 },
'Стилист': { url: 'stylist', costPerHourRUB: 3500, costPerHourUSDT: 51 },
'VBA': { url: 'vba', costPerHourRUB: 1400, costPerHourUSDT: 35 },
'Маркетолог': { url: 'marketer', costPerHourRUB: 2700, costPerHourUSDT: 30 },
'Графический дизайнер': { url: 'graphic_designer', costPerHourRUB: 2700, costPerHourUSDT: 35 },
'Установка программ': { url: 'installing_programs', costPerHourRUB: 1800, costPerHourUSDT: 27 },
'Экономическая теория': { url: 'economic_theory', costPerHourRUB: 2700, costPerHourUSDT: 30 },
'Сис админ': { url: 'sys_admin', costPerHourRUB: 1800, costPerHourUSDT: 21 },
'TypeScript': { url: 'typescript', costPerHourRUB: 2000, costPerHourUSDT: 20 },
'Восстановление данных': { url: 'data_recovery', costPerHourRUB: 1800, costPerHourUSDT: 21 },
'Настройка серверов': { url: 'setting_up_servers', costPerHourRUB: 1800, costPerHourUSDT: 27 },
'Веб дизайнер': { url: 'web_designer', costPerHourRUB: 1800, costPerHourUSDT: 21 },
'Ведение бюджета': { url: 'budgeting', costPerHourRUB: 4000, costPerHourUSDT: 40 },
'UE5': { url: 'ue5', costPerHourRUB: 2000, costPerHourUSDT: 23 },
'Бальные танцы': { url: 'ballroom_dancing', costPerHourRUB: 2500, costPerHourUSDT: 30 },
'Гипноз': { url: 'hypnosis', costPerHourRUB: 3000, costPerHourUSDT: 30 },
'Турецкий': { url: 'turkish', costPerHourRUB: 800, costPerHourUSDT: 10 },
 };

export default ButtonServiceData;
