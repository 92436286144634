import { fetchRetry } from "./fetchRetry";

export async function callBackend(args) {
  const requiredFields = ["link", "method", "headers"];
  requiredFields.forEach((fieldName) => {
    if (typeof args[fieldName] === "undefined") {
      let message = "wrong data in field " + fieldName;
      console.error(message);
      return { success: false, message: message };
    }
  });

  
  const tries = 6;
  const delay = 1000;
  const delayMultiplier = 2;
  let responce;
  let json

  try {
    responce = await fetchRetry(
      args.link,
      { tries: tries, delay: delay, delayMultiplier: delayMultiplier },
      {
        method: args.method,
        headers: args.headers,
        body: args.body && JSON.stringify(args.body),
        credentials: "include",
      }
    );
    json = await responce.json();
  } catch (error) {
    console.error("callBackend", error);
    json = { success: false, message: "fetch error" }
  }

   
  return json;
}
