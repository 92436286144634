const NonAuthPage = ({}) => {
  return (
   <div className="popup">
    <button>
    <a href="https://t.me/easehowbot">@easehowbot</a></button>
   </div>
  );
};

export default NonAuthPage;
