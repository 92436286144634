function wait(delay) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

export async function fetchRetry(
  url,
  retryOptions = { tries: 1, delay: 100, delayMultiplier: 1 },
  fetchOptions = {}
) {
  retryOptions.delayMultiplier = retryOptions.delayMultiplier ?? 1;
  function onError(error) {
    retryOptions.tries -= 1;
    if (retryOptions.tries <= 0) {
      if (
        process.env.REACT_APP_ERROR_PAGE &&
        window.location.pathname !== "/error"
      ) {
        window.location.href = "/error";
      }
      return {
        json: () => ({ success: false, message: "no answer from server" }),
      };
    }
    return wait(retryOptions.delay).then(() => {
      retryOptions.delay *= retryOptions.delayMultiplier;
      return fetchRetry(url, retryOptions, fetchOptions);
    });
  }

  let responce;
  try {
    responce = await fetch(url, fetchOptions)
    if (!responce.ok) {
      responce = await onError();
    }
  } catch (error) {
    responce = await onError(error);
  }

  return responce
}
