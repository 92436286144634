import { callBackend } from "../callBackend";
const apiPath = "api/user/analytics";

export async function sendAnalytics(data) {
  let backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;
  if (!backendDomain) {
    return { success: false, message: "no backendDomain" };
  }

  let responce = await callBackend({
    link: `${backendDomain}/${apiPath}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: {
      data: data,
    },
  });

  return responce;
}
