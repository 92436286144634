// главная
export const textWorker = `Specialist`; // как называем исполнителей контракта
export const textTitleMain = `Hourly ${textWorker}s`; // заголовок на главной

export const textBack = `Back`; //Назад
export const textProfile = `Profile`; //Профиль


// поиск услуги
export const textInputSearchServiceDefault = `Need another ${textWorker}?`; // текст кнопки призыва к действию на поиск услуги которой нет – по уумолчанию
export const textInputSearchServiceFocus = `Type need and press ↵`; // текст кнопки призыва к действию на поиск услуги которой нет – при фокусе
export const textInputSearchServiceLeaveRequest = `You will be contacted`; // текст кнопки призыва к действию на поиск услуги которой нет – при клике


// профиль
export const textTopUp = `Top up`; // текст кнопки для пополнения баланса
export const textCashOut = `Withdraw`; // текст кнопки для вывода средств

export const textRefLink = `Referral link`;
//export const textRefLinkDescription = `Пополняет баланс на 10% от стоимости заказанной услуги приглашёным новым пользователем`;
export const textRefLinkDescription = `Get 10% of the invited users’ service cost added to your balance if they haven’t used a referral link before from this app`;
//Link is copied
export const textCopyLink = `Link is copied`;

export const textBalance = `Balance`;


// карточка
export const textRate = `Rating`;
export const textDeals = `Deals`;
export const textPricePerHour = `price per 1 hour`;
//pay
export const textPay = `Start`;


// валюты
export const textFiat = `₽`; // фиат валюта
export const textCript = `USDT`; // криптовалюта валюта
export const idFiat = `RUB`; // название фиата для аналитики
export const idCript = `USDT`; // название криптовалюты для аналитики

export const currency = 90; // курс валюты textFiat:textCript
export const commissionFiat = 1.3; // комиссия фиaта
export const commissionCript = 1.15; // комиссия крипты


// архив
export const textLeaveRequest = `Leave a request`; // текст кнопки если эксперты заняты
export const premiumForUrgencyInFindingAnExpert = [
 // надбавка за срочность поиска эксперта
 { hours: 24, surcharge: 1.5 }, // 150%
 { hours: 48, surcharge: 1.0 }, // 100%
];