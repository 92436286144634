import { callBackend } from "../callBackend";

export async function getWorkerList(serviceName, limit, offset, language) {
  limit = isNaN(limit) ? 10 : parseInt(limit);
  offset = isNaN(offset) ? 0 : parseInt(offset);
  language = language ?? "en";
  const apiPath = `api/service/${serviceName}/workers?limit=${limit}&offset=${offset}&language=${language}`;

  let backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;
  if (!backendDomain) {
    return { success: false, message: "no backendDomain" };
  }

  let responce = await callBackend({
    link: `${backendDomain}/${apiPath}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return responce;
}
