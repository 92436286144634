// заявка на поиск недостающего эксперта
import React, { useState, useRef } from "react";
import { sendAnalytics } from "../functions/api/sendAnalytics";
import { AnalyticsData } from "../classes/AnalyticsData";

import {
  textInputSearchServiceDefault,
  textInputSearchServiceFocus,
  textInputSearchServiceLeaveRequest,
} from "../consts/ConstSevicePage";

export default function InputSearchService() {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPlaceholder, setCurrentPlaceholder] = useState(textInputSearchServiceDefault);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  let placeholderTimeout = useRef(null);

  function handleKeyPress(event) {
    if (event.key === "Enter" && searchQuery !== "") {
      setCurrentPlaceholder(textInputSearchServiceLeaveRequest);

      clearTimeout(placeholderTimeout.current);
      placeholderTimeout.current = setTimeout(() => {
        setCurrentPlaceholder(textInputSearchServiceFocus);
      }, 4000);

      sendAnalytics(
        new AnalyticsData({
          type: "InputSearchService",
          searchQuery: searchQuery,
        })
      );
      setSearchQuery("");
    }
  }

  function handleChange(event) {
    setSearchQuery(event.target.value);
  }

  function handleFocus(event) {
    console.log("Focus!")
    event.target.scrollIntoView({ behavior: "smooth", block: "start" })
    setIsFocused(true);
    setCurrentPlaceholder(textInputSearchServiceFocus);
  }

  function handleBlur() {
    console.log("Blur!")
    setIsFocused(false);
    setCurrentPlaceholder(textInputSearchServiceDefault);
  }

  function handleMouseEnter() {
    setIsMouseOver(true);
    if (!isFocused) {
      setCurrentPlaceholder(textInputSearchServiceFocus);
    }
  }

  function handleMouseLeave() {
    setIsMouseOver(false);
    if (!isFocused) {
      setCurrentPlaceholder(textInputSearchServiceDefault);
    }
  }

  return (
    <div className="input_search_service">
      <input
        type="text"
        placeholder={currentPlaceholder}
        onKeyDown={handleKeyPress}
        value={searchQuery}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
}
