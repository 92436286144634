// ButtonService.js – кнопка с услугой
import React from "react";
import { sendAnalytics } from "../functions/api/sendAnalytics";
import { AnalyticsData } from "../classes/AnalyticsData";

const ButtonService = ({ name, url, onClick }) => {
  async function handleClick(event) {
    if (onClick) {onClick(event)} else (console.error(`button service onclick error`, onClick))
    sendAnalytics(
      new AnalyticsData({ type: "ButtonService", name: name, url: url })
    );
  }

  return (
    <button onClick={handleClick}>
      {name}
    </button>
  );
};

export default ButtonService;
