import styles from "../styles/WorkerCard.module.css";
import {
  textFiat,
  textCript,
  textRate,
  textDeals,
  textPricePerHour,
} from "../consts/ConstSevicePage";

const WorkerCard = ({
  name,
  priceList,
  rate,
  dealAmount,
  selectedSortValue,
  desrciptionData,
  isClickable,
}) => {
  if (isNaN(rate)) {
    isNaN(rate);
    rate = 0;
  }
  if (isNaN(dealAmount)) {
    dealAmount = 0;
  }

  console.log("descriptionData", desrciptionData);
  console.log(
    "desrciptionData?.descriptionMedia",
    desrciptionData?.descriptionMedia
  );
  console.log(
    "desrciptionData && desrciptionData?.descriptionMedia",
    desrciptionData && desrciptionData?.descriptionMedia
  );

  let priceFiat = priceList.find(
    (price) => price.currency === "RUB"
  )?.amountPerHour;
  let priceCrypt = priceList.find(
    (price) => price.currency === "USDT"
  )?.amountPerHour;

  let isDividerVisible = Boolean(priceFiat) && Boolean(priceCrypt);

  let isBold = {
    fiat: selectedSortValue === "fiat",
    cript: selectedSortValue === "cript",
    rate: selectedSortValue === "rate",
    dealAmount: selectedSortValue === "dealAmount",
  };

  return (
   <div className={`${styles.worker_card} ${isClickable ? styles.clickable : ""}`}>
    <div className={styles.price_line}>
     {/* <span className={priceFiat ? isBold.fiat ? "dynamic_bold" : "" : "dynamic_display_none"}>
      {priceFiat} {textFiat}
     </span> */}
     <span className={isDividerVisible ? styles.or : "dynamic_display_none"}>||</span>
     <span className={priceCrypt ? isBold.cript ? "dynamic_bold" : "" : "dynamic_display_none"}>
      {priceCrypt} {textCript}
     </span>
    </div>
    <span className={styles.worker_statistics}>
     
     <p className={styles.deals_line}>
      <span className={styles.color_text_3}>{textRate}</span>
      <span className={isBold.rate ? "dynamic_bold" : ""}>{Math.floor(rate * 100)}%</span>
      {/* <span className={styles.gray_text}>&#8226;</span> */}
      <span className={styles.color_text_3}> </span>
      <span className={styles.color_text_3}>{textDeals}</span>
      <span className={isBold.dealAmount ? "dynamic_bold" : ""}>{dealAmount}</span>
     </p>

        <span className={styles.worker_name}>{name}</span>
      </span>
    </div>
  );
};

export default WorkerCard;
